/*eslint-disable */
<template>
  <b-container fluid>
    <b-modal v-model="showSelectPlanModal" size="xs" ok-title="Block" cancel-title="Cancel">
    <SubscriptionPlanAdd
    :propOpenedInModal="true"
    :propModuleName="modName"
    :propRoleId="role"
    :propModuleObjId="vmSelectedGideAdminCurr.user_id"
    :planExists="editablePlan"
    :propModulePlanName="sessionPlanName"
    :propModuleDesc="sessionPlanDesc"
    :propModuleOldSubsPlanId="oldSessionPlanId"
    @emitCloseSubscriptionPlanAddModal="closeSubscriptionPlanAddModal"
    />
    </b-modal>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template>
            <ValidationObserver ref="subsPlanForm">
              <form action="#">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label v-if="!isEditMode">{{cvSelectGideAdminLabel}}</label>
                    <label v-else>{{cvCounsellorLabel}}</label><br/>
                    <label v-if="isEditMode">{{vmSelectedGideAdminCurr.user_name}}</label>
                    <ValidationProvider
                    v-if="!isEditMode"
                      name="Gide Admin"
                      rules="required"
                      v-slot="{ errors }">
                      <multiselect
                        v-model="vmSelectedGideAdminCurr"
                        :tag-placeholder="cvSelectBoxText + cvSelectGideAdminLabel"
                        :placeholder="cvSelectBoxText + cvSelectGideAdminLabel"
                        :searchable="true"
                        label="user_name"
                        track-by="user_name"
                        required
                        :options="cvGideAdminOptions"></multiselect>
                      <span class="text-danger">{{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="form-row">
                  <b-button class="ml-3"
                  @click="()=>openSelectPlan(0)"
                  >
                    {{ `${isEditMode ? 'Edit/Add' : 'Add'} Plan Session 1` }}
                  </b-button>
                  <b-button class="ml-3" v-b-modal.modal-1
                 @click="()=>openSelectPlan(1)"
                  >
                  {{ `${isEditMode ? 'Edit/Add' : 'Add' } Plan Session 2` }}
                  </b-button>
                </div>
                <br/>
                <div class="form-row">
                  <template v-if="plans && plans.length">
                        <div class="row w-100 mb-4">
                          <div class="planBox col-md-6 col-md-6 col-sm-6" v-for="(eventPlan, index) of plans" :key="index+eventPlan.subs_plan_id">
                            Plan Name: {{eventPlan.subs_plan_name}} <br>
                            Plan Id: {{ eventPlan.subs_plan_id }}   <br>
                            Plan Price: {{eventPlan.subs_plan_price}} <br>
                            Gide Member Price: {{eventPlan.subs_plan_price_gideplus}}
                          </div>
                        </div>
                      </template>
                </div>
                <br/>
                <button
                    v-if="!isEditMode"
                    type="button"
                    class="btn btn-primary"
                    @click="changeAdminToCounsellor(vmSelectedGideAdminCurr)">
                    {{ cvSubmitBtn }}
                  </button>
                <br/>
                <div class="form-group">
                  <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                    <b-spinner label="Spinning">
                    </b-spinner>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import ApiResponse from "../../../Utils/apiResponse.js"
import Multiselect from "vue-multiselect"
import { ValidationProvider } from "vee-validate"
import { User } from "../../../FackApi/api/user.js"
import SubscriptionPlanAdd from "../../Gide/Subscription/SubscriptionPlanAdd.vue"

export default {
  name: "spcmCounsellorAdd",
  props: {
    plans: {
      type: Array,
      default: null
    },
    isEditMode: {
      type: Boolean,
      default: false
    },
    counsellor: {
      type: Object,
      default: null
    },
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propModuleName: {
      default: null
    },
    propModuleObjId: {
      default: null
    },
    propModulePlanName: {
      default: null
    },
    propModuleDesc: {
      default: null
    },
    propRoleId: {
      default: null
    }
  },
  components: {
    Multiselect,
    ValidationProvider,
    SubscriptionPlanAdd
  },
  data () {
    return {
      role: "USERROLE11114",
      sessionPlanDesc: null,
      sessionPlanName: null,
      oldSessionPlanId: null,
      editablePlan: null,
      modName: "counselling",
      cvAddModalHeader: "Add counsellor",
      showSelectPlanModal: false,
      cvGideAdminOptions: [],
      cvSubmitBtn: "Add",
      cvSelectBoxText: "Select ",
      cvSelectGideAdminLabel: "Gide Admin",
      cvCounsellorLabel: "Counsellor",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "subscription Plan",
      vmSelectedGideAdminCurr: this.counsellor,
      whereFilter: {
        user_role: "USERROLE11111"
      }
    }
  },
  mounted () {
    this.getGideAdmins()
  },
  methods: {
    /**
     * closeSubscriptionPlanAddModal
     */
    closeSubscriptionPlanAddModal (plan, planExists) {
      this.showSelectPlanModal = false
      if (!planExists) { this.plans.push(plan) }
    },
    /**
     * openSelectPlanModal
     */
    openSelectPlan (planIndex) {
      if (!this.isEditMode || !this.plans[planIndex]) {
        // Add mode
        this.sessionPlanName = `Session Plan ${planIndex + 1}`
        this.sessionPlanDesc = `Session Plan ${planIndex + 1}`
        this.oldSessionPlanId = null
      }
      else {
        // Edit mode
        this.oldSessionPlanId = this.plans[planIndex].subs_plan_id
      }

      if (!this.vmSelectedGideAdminCurr.user_id) {
        console.log("Please select gide admin!")
        return
      }

      this.showSelectPlanModal = !this.showSelectPlanModal
      this.editablePlan = this.plans[planIndex]
    },
    /**
     * changeAdminToCounsellor
     */
    async changeAdminToCounsellor () {
      try {
        this.cvLoadingStatus = true
        let payload = {
          curr_user_id: this.vmSelectedGideAdminCurr.user_id,
          user_role: "USERROLE11118"
        }

        let userEditResp = await User.userEditConsole(this, payload)
        ApiResponse.responseMessageDisplay(this, userEditResp)

        if (userEditResp && !userEditResp.resp_status) {
          return false
        }

        this.vmSelectedGideAdminCurr.user_role = "USERROLE11118"
        this.$emit("emitCounsellorAdd", this.vmSelectedGideAdminCurr)
      }
      catch (err) {
        console.error("Exception occurred at userEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * getGideAdmins
     */
    async getGideAdmins () {
      try {
        // console.log(moment(this.whereFilter.dateRange.startDate).format("DD-MM-YYYY")) userListSearch

        let userListResp = await User.userListSearch(this, this.whereFilter)
        if (userListResp.resp_status) {
          this.cvGideAdminOptions = userListResp.resp_data.data
        }
        else {
          this.cvGideAdminOptions = []
          this.toastMsg = userListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at userList() and Exception:", err.message)
      }
    }
  }
}
</script>
