<template>
  <b-container fluid>
    <b-modal
      v-model="showModelUserEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl">
      <UserEdit :propOpenedInModal="true" :propUserObj="userEditObj" @emitCloseUserEditModal="closeUserEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="userEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeUserEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>
    <b-row class="heading_alignment">
      <b-col md="12">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>

          <template>
            <b-row class="w-100 mb-3">
              <b-col class="col-12 col-sm-12 col-md-6 col-lg-7 mb-2">
                <b-form-input class="search_box"
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col class="col-12 col-sm-7 col-md-2 col-lg-2 mb-2">
                <template>
                  <b-btn variant="primary" class="Download_button" v-b-modal.modal-1 @click="()=>{counsellorObj={};counsellorPlans=[]; isEditMode=false;showModalCounsellorAdd=!showModalCounsellorAdd}"> Add New
                  </b-btn>
                </template>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="userObjList && userObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="userObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(user_name)="data">
                    {{data.item.user_name}}<br/>
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="editCounsellor(data.item)" v-if="!data.item.editable" title="Edit plans">
                      <i class="ri-ball-pen-fill m-0"></i>
                    </b-button>
                    <b-button variant=" iq-bg-success mr-1 mb-1 ml-2" title="Edit role" size="sm" @click="userEdit('MODAL', data.item)" v-if="!data.item.editable">
                      <i class="fa fa-pencil-square-o"></i>
                    </b-button>
                    </template>
                  <template v-slot:cell(user_email)="data">
                    <p v-html="data.item.user_email" style="word-wrap: break-word">
                    </p>
                  </template>

                  <template v-slot:cell(user_mobile)="data">
                    <p v-html="data.item.user_mobile" style="word-wrap: break-word">
                    </p>
                  </template>

                  <template v-slot:cell(subs_plan_name)="data">
                    <p v-html="data.item.subs_plan_name" style="word-wrap: break-word">
                    </p>
                    <i style="font-size:10px" title="Counsellor id" v-if="data.item.subs_plan_id!=undefined">Id: {{data.item.subs_plan_id}}</i> <br/>
                  </template>

                  <template v-slot:cell(subs_plan_price)="data">
                    <p v-html="data.item.subs_plan_price" style="word-wrap: break-word">
                    </p>
                  </template>

                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal v-model="showModalCounsellorAdd" size="xl" :title="`${isEditMode?'Edit':'Add new'} Counsellor`" ok-title="Block" cancel-title="Cancel">
      <spcmCounsellorAdd @emitCounsellorAdd="counsellorAdd"
      @refreshCounsellorData="this.userList()"
      :counsellor="counsellorObj"
      :plans="counsellorPlans"
      :isEditMode="isEditMode"
      />
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="closeCounsellorAddModal">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>
<style scoped>
.dateRange {
  float: right;
  }
  .iq-card .iq-card-header{
    padding: 0px!important;
  }
  .user-card{
    font-size: 30px !important;
  }
</style>
<script>
import { User } from "../../../FackApi/api/user.js"

import spcmCounsellorAdd from "./spcmCounsellorAdd.vue"
import { SubscriptionPlans } from "../../../FackApi/api/Subscription/subscriptionPlan"
import UserEdit from "../../Gide/User/UserEdit.vue"
export default {
  name: "SpcmCounsellorList",
  components: {
    spcmCounsellorAdd,
    UserEdit
  },
  data () {
    return {
      counsellorPlans: [],
      isEditMode: false,
      counsellorObj: {},
      cvModuleFile: "user",
      cvModulePrefix: "USER",
      cvCardTitle: "Counsellors",
      cvbtnEditCounsellor: "Edit",
      cvSearchText: "Type to Search",
      cvbtnModalCancel: "Cancel",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      showModalCounsellorAdd: false,
      sortBy: "name",
      sortDesc: false,
      showModelUserEdit: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "user name", key: "user_name", class: "text-left align-text-top pointer", sortable: true },
        { label: "user email", key: "user_email", class: "text-left align-text-top", sortable: true },
        { label: "user mobile", key: "user_mobile", class: "text-left align-text-top", sortable: true },
        { label: "subscription plan name", key: "subs_plan_name", class: "text-left align-text-top", sortable: true },
        { label: "subscription plan price", key: "subs_plan_price", class: "text-left align-text-top", sortable: true }
      ],
      userObjList: [],
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      whereFilter: {
        user_role: "USERROLE11118"
      },
      cvbtnModalExpand: "Expand",
      cvEditModalHeader: "Edit User Role",
      userEditObj: {}
    }
  },
  async mounted () {
    this.userList()
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    /**
     * close Counsellor Add modal;
     */
    closeCounsellorAddModal () {
      this.showModalCounsellorAdd = false
      this.userList()
    },
    /**
     * userEdit
     */
    userEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/user_edit/" + this.userEditObj.user_id)
        }
        else {
          this.userEditObj = item
          this.showModelUserEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at userEdit() and Exception:", err.message)
      }
    },
    /**
     * editCounsellor
     */
    async editCounsellor (counsellor) {
      const subPlanResp = await SubscriptionPlans.subscriptionPlanListCustom(this, counsellor.user_id)

      if (subPlanResp.resp_status) {
        this.counsellorPlans = subPlanResp.resp_data.data
      }
      else {
        this.counsellorPlans = []
      }
      this.isEditMode = true
      this.counsellorObj = counsellor
      this.showModalCounsellorAdd = !this.showModalCounsellorAdd
    },
    /**
     * counsellorAdd
     * @param {*} counsellor
     */
    counsellorAdd () {
      this.userList()
      this.showModalCounsellorAdd = false
    },
    /**
     * closeUserEditModal
     */
    closeUserEditModal () {
      this.showModelUserEdit = false
    },
    /**
     * userList
     */
    async userList () {
      try {
        // console.log(moment(this.whereFilter.dateRange.startDate).format("DD-MM-YYYY")) userListSearch

        let userListResp = await User.userListSearch(this, this.whereFilter)
        if (userListResp.resp_status) {
          this.userObjList = userListResp.resp_data.data
          this.totalRows = userListResp.resp_data.count
        }
        else {
          this.userObjList = []
          this.toastMsg = userListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at userList() and Exception:", err.message)
      }
    }
  }
}
</script>
