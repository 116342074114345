/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{ cvCardTitle }}
            </h4>
          </template>
          <template>
            <p v-if="!propOpenedInModal">
              {{ cvCardSubHeader }}
            </p>
            <ValidationObserver ref="subsPlanForm">
              <form action="#">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="subs_plan_id">{{cvSubsPlanId}} <small>(Only Alphabets, Numbers & Dash is Allowed)</small></label>
                    <ValidationProvider
                      name="Plan Id"
                      :rules="{required, max:50, regex:/^([a-zA-Z0-9\-])*$/}"
                      v-slot="{ errors }">
                      <input
                        id="subs_plan_id"
                        v-model="vmSubscriptionPlanFormData.subs_plan_id"
                        type="text"
                        class="form-control"
                        required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="subs_plan_name">{{cvSubsPlanNameLabel}}</label>
                    <ValidationProvider
                      name="Plan Name"
                      rules="required|max:50"
                      v-slot="{ errors }">
                      <input
                        id="subs_plan_name"
                        v-model="vmSubscriptionPlanFormData.subs_plan_name"
                        type="text"
                        class="form-control"
                        required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label>{{cvSubsPlanModuleTypeLabel}}</label>
                    <ValidationProvider
                      name="Plan Applicable for"
                      rules="required"
                      v-slot="{ errors }">
                      <multiselect
                        v-model="vmSelectedSubsModType"
                        :options="subscriptionPlanModuleTypeObjList"
                        :placeholder="cvSelectBoxText + cvSubsPlanModuleTypeLabel"
                        :tag-placeholder="cvSelectBoxText + cvSubsPlanModuleTypeLabel"
                        label="module_name"
                        track-by="module_abbr">
                      </multiselect>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label>{{cvSubsPlanIntervalLabel}}</label>
                    <ValidationProvider
                      name="Plan Interval"
                      rules="required"
                      v-slot="{ errors }">
                      <multiselect
                        v-model="vmSelectedSubsInterval"
                        :options="subscriptionPlanTypeObjList"
                        :placeholder="cvSelectBoxText + cvSubsPlanIntervalLabel"
                        :tag-placeholder="cvSelectBoxText + cvSubsPlanIntervalLabel"
                        label="spi_name"
                        track-by="id">
                      </multiselect>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="subs_plan_interval_count">{{cvSubsPlanIntervalCountLabel}}</label>
                    <ValidationProvider
                      name="Plan Interval Count"
                      rules="required|max:1"
                      v-slot="{ errors }">
                      <input
                        id="subs_plan_interval_count"
                        v-model="vmSubscriptionPlanFormData.subs_plan_interval_count"
                        type="number"
                        class="form-control"
                        required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label>{{cvSubsPlanModulesLabel}}</label>
                    <br />
                    <span
                      v-for="(module) in vmModuleList"
                      :key="module.id">
                      <b-badge
                        style="cursor:pointer;"
                        :style="(selectedModuleObjList && selectedModuleObjList[module.id]) ? setModuleChipStyle(true) : setModuleChipStyle(false)"
                        class="border mr-2 mb-2 font-weight-normal"
                        variant="none"
                        @click="selectModule(module)">
                        <span>{{ module.mod_name }}</span>
                      </b-badge>
                    </span>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label>{{cvSubsPlanTypeLabel}}</label>
                    <select
                      v-model="vmSubscriptionPlanFormData.subs_plan_type"
                      class="form-control"
                      type="text"
                    >
                    <option v-for="(value, index) in PlanType" :key="index">{{index}}</option>
                    </select>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="sub_plan_module_obj_id">{{cvSubsPlanModObjId}}</label>&nbsp;&nbsp;<i class="fa fa-info-circle mr-2 primary-color pointer" title="For Event & Activity based plans, this is generated dynamically. For products like GPaths, Counselling it has to be set manually"></i>
                    <input
                        id="sub_plan_module_obj_id"
                        v-model="vmSubscriptionPlanFormData.sub_plan_module_obj_id"
                        type="text"
                        class="form-control"/>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label>{{cvSubsPlanCurrencyLabel}}</label>
                    <ValidationProvider
                      name="Plan Currency"
                      rules="required"
                      v-slot="{ errors }">
                      <multiselect
                        v-model="vmSelectedSubsPlanCurrMixin"
                        :tag-placeholder="cvSelectBoxText + cvSubsPlanCurrencyLabel"
                        :placeholder="cvSelectBoxText + cvSubsPlanCurrencyLabel"
                        :searchable="true"
                        label="currency_name"
                        track-by="id"
                        :options="cvCurrencyOptions"></multiselect>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label>{{cvSubsPlanPriceLabel}}</label>
                    <ValidationProvider
                      name="Plan Price"
                      rules="required|numeric"
                      v-slot="{ errors }">
                      <input
                        v-model="vmSubscriptionPlanFormData.subs_plan_price"
                        type="text"
                        class="form-control"
                        required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label>{{cvSubsPlanPriceGidePlusLabel}}</label>
                    <ValidationProvider
                      name="Plan Price Gide Plus"
                      rules="required|numeric"
                      v-slot="{ errors }">
                      <input
                        v-model="vmSubscriptionPlanFormData.subs_plan_price_gideplus"
                        type="text"
                        class="form-control"
                        required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label>Plan Price In USD (For other countries) If Applicable (0 for N/A)</label>
                    <ValidationProvider
                      name="Plan Price In USD"
                      rules="numeric"
                      v-slot="{ errors }">
                      <input
                        v-model="vmSubscriptionPlanFormData.subs_plan_usd_price"
                        type="text"
                        class="form-control"
                        required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label>Gide Plus Price In USD (For other countries) If Applicable</label>
                    <ValidationProvider
                      name="Plan Price for Gide Plus In USD"
                      rules="numeric"
                      v-slot="{ errors }">
                      <input
                        v-model="vmSubscriptionPlanFormData.subs_plan_usd_price_gideplus"
                        type="text"
                        class="form-control"
                        required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-12 mb-3" :key="update">
                    <label>{{cvSubsPlanDescLabel}}</label>
                    <ValidationProvider
                      name="Plan Desc"
                      rules="max:256"
                      v-slot="{ errors }">
                      <b-form-textarea
                        v-model="vmSubscriptionPlanFormData.subs_plan_desc"
                        :placeholder="cvCourDescLabel"
                        rows="2"
                        max-rows="6"
                        required></b-form-textarea>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group">
                  <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                    <b-spinner label="Spinning">
                    </b-spinner>
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="subscriptionPlanAdd()">
                    {{ cvSubmitBtn }}
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { SubscriptionPlans } from "../../../FackApi/api/Subscription/subscriptionPlan"
import ApiResponse from "../../../Utils/apiResponse.js"
import Multiselect from "vue-multiselect"
import CountryMixin from "../../../Utils/Mixins/country"
import { ValidationProvider } from "vee-validate"
import { modules } from "../../../FackApi/api/modules"
import PlanType from "../../../FackApi/json/planType.json"
import subsRuleCounselling from "../../../FackApi/json/subscription_rules_counselling.json"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "SubscriptionPlanAdd",
  props: {
    propModuleOldSubsPlanId: {
      type: String,
      default: null
    },
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propModuleName: {
      default: null
    },
    propModuleObjId: {
      default: null
    },
    propModulePlanName: {
      default: null
    },
    propModuleDesc: {
      default: null
    },
    propRoleId: {
      default: null
    },
    planExists: {
      type: Object,
      default: null
    }
  },
  components: {
    Multiselect,
    ValidationProvider
  },
  data () {
    return {
      apiName: "subscriptionPlan_add",
      cvCardTitle: "Add subscription Plan",
      cvCardSubHeader: "Add subscription Plan",
      cvSubmitBtn: "Add",
      cvSelectBoxText: "Select ",
      cvSubsPlanId: "Plan Id",
      cvSubsPlanNameLabel: "Plan Name",
      cvSubsPlanIntervalLabel: "Plan Interval",
      cvSubsPlanTypeLabel: "Plan Type",
      cvSubsPlanModObjId: "Plan Module Object Id",
      cvSubsPlanIntervalCountLabel: "Plan Interval Count",
      cvSubsPlanModulesLabel: "Plan Modules",
      cvSubsPlanCurrencyLabel: "Plan Currency",
      cvSubsPlanPriceLabel: "Plan Price",
      cvSubsPlanPriceGidePlusLabel: "Plan Price For Gide Plus ",
      cvSubsPlanModuleTypeLabel: "Plan Applicable for",
      cvSubsPlanDescLabel: "Plan Description",
      cvCourDescLabel: "Write Your Description",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "subscription Plan",
      vmSubscriptionPlanFormData: Object.assign({}, this.initFormData()),
      vmSubscriptionPlanDesc: null,
      vmSubscriptionPlanType: null,
      subscriptionPlanTypeObjList: [],
      selectedModuleObjList: {},
      subscriptionPlanModuleTypeObjList: [
        {
          "module_abbr": "STUDENT",
          "module_name": "Student",
          "module_role_id": "USERROLE11114"
        },
        {
          "module_abbr": "UNIV",
          "module_name": "University",
          "module_role_id": "USERROLE11115"
        },
        {
          "module_abbr": "ORG",
          "module_name": "Organization",
          "module_role_id": "USERROLE11116"
        }
      ],
      vmSelectedSubsInterval: {},
      vmSelectedSubsPlanCurr: {},
      vmSelectedSubsModType: {},
      vmModuleList: [],
      update: 0,
      PlanType: PlanType,
      subsRuleCounselling: subsRuleCounselling
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mixins: [ CountryMixin ],
  mounted () {
    this.initialiseData()
    if (this.planExists) {
      const planType = Object.entries(this.PlanType).find(([, type]) => {
        return type == this.vmSubscriptionPlanFormData.subs_plan_type
      })[0]
      this.vmSubscriptionPlanFormData.subs_plan_type = planType
    }

    this.vmSubscriptionPlanFormData.sub_plan_module_obj_id = this.propModuleObjId

    this.subscriptionPlanTypeList()
    this.moduleList()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      this.vmSelectedSubsInterval = {}
      this.vmSelectedSubsPlanCurr = {}
      this.selectedModuleObjList = {}
      return {
        "subs_plan_name": "",
        "subs_plan_interval": "",
        "subs_plan_modules": "",
        "subs_plan_currency": "",
        "subs_plan_price": "",
        "subs_plan_type": {}
      }
    },
    /**
     * initialiseData
     */
    initialiseData () {
      if (this.planExists) {
        this.vmSubscriptionPlanFormData = this.planExists
        this.curSelectedCurrency(this.planExists.subs_plan_currency)
      }
    },
    /*
     * module_list
     */
    async moduleList () {
      let resp = await modules.moduleList(this)
      if (!resp.resp_status) {
        return false
      }
      this.vmModuleList = resp.resp_data.data

      if (this.propModuleName) {
        const findModIndex = this.vmModuleList.findIndex(mod => mod.mod_name === this.propModuleName)

        if (findModIndex >= 0) {
          this.$set(this.selectedModuleObjList, this.vmModuleList[findModIndex].id, this.vmModuleList[findModIndex])
        }

        if (this.propModulePlanName) {
          this.vmSubscriptionPlanFormData.subs_plan_name = this.propModulePlanName
        }

        if (this.propModuleDesc) {
          this.vmSubscriptionPlanFormData.subs_plan_desc = this.propModuleDesc
          this.update += 1
        }

        if (this.propRoleId) {
          const findIndex = this.subscriptionPlanModuleTypeObjList.findIndex(ele => ele.module_role_id === this.propRoleId)
          if (findIndex >= 0) {
            this.vmSelectedSubsModType = this.subscriptionPlanModuleTypeObjList[findIndex]
          }
        }
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmSubscriptionPlanFormData) {
          if (!this.vmSubscriptionPlanFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * subscriptionPlanTypeList
     */
    async subscriptionPlanTypeList () {
      try {
        let subscriptionPlanTypeListResp = await SubscriptionPlans.subscriptionPlanTypeList(this)
        if (subscriptionPlanTypeListResp.resp_status) {
          this.subscriptionPlanTypeObjList = subscriptionPlanTypeListResp.resp_data.data
          const planInterval = this.subscriptionPlanTypeObjList.find((e) => {
            return e.spi_name == this.vmSubscriptionPlanFormData.subs_plan_interval
          })
          this.vmSelectedSubsInterval = planInterval
        }
      }
      catch (err) {
        console.log("Exception occurred at subscriptionPlanTypeList() and Exception:", err.message)
      }
    },
    /**
     * subscriptionPlanAdd
     */
    async subscriptionPlanAdd () {
      let valid = await this.$refs.subsPlanForm.validate()

      if (!valid) {
        return
      }

      try {
        this.cvLoadingStatus = true
        // Extract value of subscription plan interval object
        if (this.vmSelectedSubsInterval && Object.keys(this.vmSelectedSubsInterval).length > 0) {
          this.vmSubscriptionPlanFormData.subs_plan_interval = this.vmSelectedSubsInterval.spi_name
        }

        if (this.vmSelectedSubsPlanCurrMixin && Object.keys(this.vmSelectedSubsPlanCurrMixin).length > 0) {
          this.vmSubscriptionPlanFormData.subs_plan_currency = JSON.stringify(this.vmSelectedSubsPlanCurrMixin)
        }

        if (this.selectedModuleObjList && Object.keys(this.selectedModuleObjList).length > 0) {
          this.vmSubscriptionPlanFormData.subs_plan_modules = JSON.stringify(this.selectedModuleObjList)
        }

        if (this.propModuleName == "counselling") {
          this.vmSubscriptionPlanFormData.subs_plan_rules = JSON.stringify(this.subsRuleCounselling)
        }

        if (this.vmSelectedSubsModType && Object.keys(this.vmSelectedSubsModType).length > 0) {
          this.vmSubscriptionPlanFormData.subs_plan_allowed_role = this.vmSelectedSubsModType.module_role_id
        }

        if (this.propModuleObjId) {
          this.vmSubscriptionPlanFormData.sub_plan_module_obj_id = this.propModuleObjId
        }

        if (this.propModuleOldSubsPlanId) {
          this.vmSubscriptionPlanFormData.old_subs_plan_id = this.propModuleOldSubsPlanId
        }

        if (this.vmSubscriptionPlanFormData.subs_plan_type) {
          this.vmSubscriptionPlanFormData.subs_plan_type = this.PlanType[this.vmSubscriptionPlanFormData.subs_plan_type]
        }

        let subscriptionPlanAddResp = await SubscriptionPlans.subscriptionPlanAdd(this, this.vmSubscriptionPlanFormData)
        if (subscriptionPlanAddResp.resp_status) {
          this.$emit("subscriptionPlanAdded", subscriptionPlanAddResp.resp_data)
        }

        await ApiResponse.responseMessageDisplay(this, subscriptionPlanAddResp)
        if (subscriptionPlanAddResp && !subscriptionPlanAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseSubscriptionPlanAddModal", subscriptionPlanAddResp.resp_data, this.planExists)
        }

        this.$refs.subsPlanForm.reset()
        this.vmSubscriptionPlanFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at subscriptionPlanAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * setModuleChipStyle
     */
    setModuleChipStyle (active) {
      if (active) {
        return {
          "color": "#FFFFFF",
          "border-color": "#51B5FD",
          "background-color": "#51B5FD"
        }
      }

      return {
        "color": "",
        "border-color": ""
      }
    },
    /**
     * selectModule
     */
    selectModule (module) {
      /*
      if (this.propModuleName) {
        // If u return from here the module will not get set. So test before u enable return
        return
      }
      */

      if (!this.selectedModuleObjList) {
        this.selectedModuleObjList = {}
      }

      if (this.selectedModuleObjList[module.id]) {
        this.$delete(this.selectedModuleObjList, module.id)
      }
      else {
        this.$set(this.selectedModuleObjList, module.id, module)
      }
    }
  }
}
</script>
